/*=====================================================
18. wpo-service-single
======================================================*/
.wpo-service-single-area{
    .wpo-service-single-wrap{
        .wpo-service-single-title{
            h3{
                font-weight: 500;
                font-size: 35px;
                line-height: 130.5%;
                margin-bottom: 20px;

                @media(max-width:767px){
                    font-size: 25px;
                }
            }
        }
        .fa- .fa{
            color: red;
            width: 70px;
            height: 70px;
          }
          
        .wpo-service-single-item{
            margin-bottom: 40px;
            .wpo-service-single-main-img{
                margin-bottom: 30px;
            }

        }
        .list-widget{
            max-width: 590px;

            ul{
                list-style: none;

                li{
                    padding: 10px 0;
                    padding-left: 35px;
                    position: relative;

                    &:before{
                       position: absolute;
                       left: 0;
                       top: 50%;
                       transform: translateY(-50%);
                       width: 25px;
                       height: 25px;
                       line-height: 25px;
                       border-radius: 50%;
                       background: transparentize($theme-primary-color, .9);
                       content: "\e64c";
                       font-family: "themify";
                       text-align: center;
                       color: $theme-primary-color;
                       font-size: 14px;
                    }
                }
            }
        }

        .wpo-p-details-img{
            margin-bottom: 10px;
        }

        .wpo-service-area{
            padding-bottom: 0;

            .wpo-service-item {
                padding: 20px;
                box-shadow: 0px 0px 20px 0px rgba(20, 33, 43, 0.1);

                @media(max-width:1200px){
                    padding: 20px 10px;
                  }
                @media(max-width:991px){
                   margin-bottom: 20px;
                }

                h2{
                    font-weight: 600;
                    font-size: 22px;
                    margin-bottom: 12px;
                    margin-top: 10px;

                    @media(max-width:1400px){
                      font-size: 19px;
                      margin-bottom: 10px;
                    }
                    @media(max-width:1200px){
                      font-size: 17px;
                      margin-bottom: 10px;
                    }
                    @media(max-width:991px){
                      font-size: 22px;
                    }
                }
                p{
                    margin-bottom: 5px;
                }

                .fi{
                    &:before{
                        font-size: 40px;
                        color: $theme-primary-color;
                    }
                }
            }
        }

        .wpo-service-contact-area{
            padding: 30px;
            box-shadow: 0px 2px 8px 2px rgba(22, 14, 71, 0.1);
            border-radius: 20px;
            background: $white;

            .wpo-contact-title{
                margin-bottom: 30px;
            }

            .wpo-contact-form-area{
                .col{
                   margin-bottom: 30px;
                }
                .form-control{
                    height: 50px;
                    border:0 ;
                    border-bottom: 1px solid $border-color;
                    border-radius: 0;

                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }
                textarea.form-control{
                    height: 120px;
                }
                select.form-control{
                    background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
                }

                .submit-area{
                    button{
                        border-radius: 6px;
                    }
                }
                .errorMessage {
                    color: red;
                    margin-top: 5px;
                }
            }
        }
    }
}